import React from 'react'
import useScrollVisibility from 'hooks/useScrollVisibility'

function Footer() {
  const [isVisible, sectionRef] = useScrollVisibility('footer-section')

  const getCurrentYear = () => {
    return new Date().getFullYear()
  }

  return (
    <footer ref={sectionRef} id='footer-section' className='flex w-full flex-col items-center justify-center bg-light'>
      <div className={`grid grid-cols-1 items-center md:grid-cols-2 ${isVisible ? 'animate-scaleUp' : 'opacity-0'}`}>
        <div className='m-4 flex flex-col justify-around gap-8 md:mx-12 md:flex-row'>
          <div className='flex flex-1 flex-col justify-center gap-4'>
            <header className='text-3xl font-medium'>Contatos</header>
            <ul className='flex flex-col gap-2'>
              <li className='text-lg'>
                <a href='https://maps.app.goo.gl/BUqVZZLZ4HYjwwYf6' target='_blank' className='hover:opacity-75'>
                  Av. Estevão Márciano dos Santos, 1110 - Centro, Ivaiporã - PR, 86870-000
                </a>
              </li>
              <li className='text-lg'>
                <a href='mailto:vilarealodontologia@gmail.com' target='_blank' className='hover:opacity-75'>
                  vilarealodontologia@gmail.com
                </a>
              </li>
              <li className='text-lg'>
                <a href='tel:5543999512211' target='_blank' className='hover:opacity-75'>
                  (43) 9 9951-2211
                </a>
              </li>
              <li className='text-lg'>
                <a href='tel:4334723276' target='_blank' className='hover:opacity-75'>
                  (43) 3472-3276
                </a>
              </li>
            </ul>
          </div>

          <div className='flex flex-1 flex-col gap-4'>
            <header className='text-3xl font-medium'>Redes Socias</header>
            <ul className='flex flex-col gap-2'>
              <li className='text-lg'>
                <a href='https://www.instagram.com/vilarealodontologia' target='_blank' className='hover:opacity-75'>
                  Instagram
                </a>
              </li>
              <li className='text-lg'>
                <a
                  href='https://www.facebook.com/people/Vila-Real-Odontologia-e-Sa%C3%BAde/100075834341478/?_rdr'
                  target='_blank'
                  className='hover:opacity-75'
                >
                  Facebook
                </a>
              </li>
              <li className='text-lg'>
                <a
                  href='https://www.tiktok.com/@odontologia.vilareal?_t=8rMRV6p7Aq0&_r=1'
                  target='_blank'
                  className='hover:opacity-75'
                >
                  TikTok
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='flex items-center justify-center'>
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d22056.28924365443!2d-51.68514682110049!3d-24.244177684195396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ec158d5383692f%3A0x1b82502764a82871!2sVila%20Real%20Odontologia%20e%20Sa%C3%BAde%20%7C%20Dentista%20Ivaipor%C3%A3!5e0!3m2!1spt-BR!2sbr!4v1718040443520!5m2!1spt-BR!2sbr'
            referrerPolicy='no-referrer-when-downgrade'
            className='min-h-96 w-full'
            title='Google Maps'
            loading='lazy'
          ></iframe>
        </div>
      </div>

      <div
        className={`text-lightDark flex w-full flex-col justify-around gap-1 bg-white py-4 text-center text-xs md:flex-row ${isVisible ? 'animate-slide-down' : 'opacity-0'}`}
      >
        <a href='https://dorac.com.br' target='_blank' className='hover:opacity-75'>
          &copy;{getCurrentYear()} Vila Real Odontologia - Todos os direitos reservados. Desenvolvido por{' '}
          <span className='hover:underline'>Agência Dorac</span>
        </a>
      </div>
    </footer>
  )
}

export default Footer
